body {
  background-color: $color-blue;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
}

.c-height__100 {
  height: 100px;
}

.c-page-container {
  display: flex;
  justify-content: center;
  width: 100vw;
}

.c-page-container-normal-position {
  position: fixed;
  top: $height-navigation-controls + $height-welcome-normal-header;
  left: 0;
  height: calc(100vh - #{$height-navigation-controls} - #{$height-welcome-normal-header} - #{$height-bottom-tab-bar});
}

.c-page-container-home-position {
  position: fixed;
  top: $height-navigation-controls + $height-welcome-home-header;
  left: 0;
  height: calc(100vh - #{$height-navigation-controls} - #{$height-welcome-home-header} - #{$height-bottom-legal-bar});
}

.c-page-content {
  width: 1080px;
}

.c-button-grid {
  display: grid;
  grid-template-columns: 460px 460px;
  column-gap: 20px;
  row-gap: 20px;
  justify-content: center;
}

.c-external-iframe {
  border: 0;
  width: 1080px;
  height: calc(100vh - #{$height-navigation-controls} - #{$height-welcome-normal-header} - #{$height-bottom-tab-bar});
  background-color: $color-white;
}

.c-iframe__loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 1080px;
  height: calc(100vh - #{$height-navigation-controls} - #{$height-welcome-normal-header} - #{$height-bottom-tab-bar});
  background-color: $color-white;
}

.c-navigation-controls-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background-color: $color-black;
  width: 100vw;
  height: $height-navigation-controls;
}

.c-navigation-controls {
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 1080px;
  padding-left: 70px;
  padding-right: 70px;
  align-items: center;
}

.c-modal {
  background: rgba($color-black, 0.70);
  z-index: $zindex-modal;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-modal-content-normal {
  background: $color-white;
  padding: 20px;
  width: 400px;
  border-radius: 5px;
}

.c-welcome-header-container {
  position: fixed;
  top: $height-navigation-controls;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
}

.c-welcome-container-normal-height {
  height: $height-welcome-normal-header;
}

.c-welcome-container-home-height {
  height: $height-welcome-home-header;
}

.c-welcome-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1080px;
  padding-left: 70px;
  padding-right: 70px;
}

.c-welcome-header-weather-row {
  display: flex;
  flex-direction:row;
  justify-content: flex-end;
  align-items: center;
}

.c-bottom-tab-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  height: $height-bottom-tab-bar;
  color: $color-white;
  background-color: $color-black;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $zindex-bottom-tab-bar
}


.c-bottom-legal-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  height: $height-bottom-legal-bar;
  width: 100vw;
  color: $color-white;
  background-color: $color-black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.c-new-airport-banner-logo {
  position: absolute;
  top: 35px;
  left: 47px;
}

