.l-page-content__standard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.l-padding__left30 {
  padding-left: 30px;
}

.l-padding__bottom30 {
  padding-bottom: 30px;
}

.l-padding__left70 {
  padding-left: 70px;
}

.l-padding_right10 {
  padding-right: 10px;
}

.l-padding__right30{
  padding-right: 30px;
}

.l-padding_right70 {
  padding-right: 70px;
}

.l-padding_leftRight70 {
  padding-left: 70px;
  padding-right: 70px;
}

.l-margin__top30 {
  margin-top: 30px;
}

.l-margin__top16 {
  margin-top: 16px;
}

.l-margin__top17 {
  margin-top: 17px;
}

.l-margin__top50 {
  margin-top: 50px;
}

.l-padding__bottom20 {
  padding-bottom: 40px;
}

.l-margin__left5 {
  margin-left: 5px;
}

.l-margin__left17 {
  margin-left: 17px;
}

.l-margin__left68 {
  margin-left: 68px;
}

.l-margin_top35 {
  margin-top: 35px;
}

.l-margin_top95 {
  margin-top: 95px;
}

.l-margin_bottom60 {
  margin-bottom: 60px;
}

.l-flex__start-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  white-space: pre-wrap;
}

.l-flex__center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.l-flex__middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.l-flex__bottomleft {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
}

.l-text-align__right {
  text-align: right;
}

.l-overflow__y {
  overflow-y: auto;
  overflow-x: hidden;
}

.l-overflow__hidden {
  overflow: hidden;
}

.l-position__relative {
  position: relative;
}